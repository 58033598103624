<template>
  <b-card class="admin-variations">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="categoryRef"
          :button-label="$t('Add Variations')"
          :columns="categoryColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="getApiEndpoint()"
          @add-new-info="showAddModal"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showVariationModal"
      :no-close-on-backdrop="true"
      :title="dataInfo && dataInfo.id ? 'Edit Varaition' : 'Add Varaition'"
      size="lg"
      ok-only
      :ok-title="$t('Save Changes')"
      @hidden="showVariationModal = false"
      @ok="saveData"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Title')">
            <b-form-input
              v-model="dataInfo.title"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group :label="$t('Status')">
            <b-form-select
              v-model="dataInfo.status"
              :options="[
                {
                  text: 'Active',
                  value: '1',
                },
                {
                  text: 'Inactive',
                  value: '0',
                },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Variations')">
            <b-form-tags v-model="dataInfo.variation_values" />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-form-group :label="$t('Shop Name')">
            <b-form-select
              v-model="dataInfo.store_id"
              :options="shopSelectionList"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="dataInfo.multiple"
            switch
            inline
          >
            <span>{{ $t('Multi Select') }}</span>
          </b-form-checkbox>
        </b-col> -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center"
        >
          <b-form-checkbox
            v-model="dataInfo.optional"
            switch
            inline
          >
            <span>{{ $t('Optional') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BFormSelect, BModal, BFormCheckbox, BFormTags } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useAdminUi } from '../useAdmin'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    GoodDataTable,
    BCard,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTags,
  },
  data() {
    return {
      filterDates: {},
      shopSelectionList: [],
      showVariationModal: false,
      dataInfo: {
        store_id: '',
        title: '',
        status: '',
        optional: 0,
        multiple: 0,
        id: 0,
        variation_values: [],
      },
      shopList: [],
      categoryColumns: [
        {
          label: 'Shop Name',
          field: 'store_id',
          rField: 'store_id_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (this.shopList.length) {
              const isShopExist = this.shopList.find(x => x.id === props.store_id)
              if (isShopExist) {
                return isShopExist.name
              }
            }
            return ''
          },
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          rField: 'status_text',
          align: 'center',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.status === 1 ? 'Active' : 'Inactive'),
        },
        {
          label: 'Variations',
          field: 'variation_values',
          rField: 'variation_values_text',
          useResolver: true,
          useRenderer: true,
          renderer: props => (props.variation_values ? props.variation_values.join(', ') : ''),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.editCategory(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('status'),
              text: 'Change Status',
              action: props => {
                this.$swal({
                  title: this.$t('You want to change the status?'),
                  iconHtml: this.$helpers.swalIcon('confirm.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.changeCategoryStatus(props)
                  }
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete this varaition?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.deleteVariation(props)
                  }
                })
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.adminStoreList()
  },
  methods: {
    showAddModal() {
      Object.keys(this.dataInfo).forEach(x => {
        this.dataInfo[x] = ''
      })
      this.dataInfo.variation_values = []
      this.showVariationModal = true
    },
    updateItemsForShop() {
      const { updateVariation } = useAdminUi()
      showLoader()
      const formData = this.getFormData()
      // eslint-disable-next-line no-underscore-dangle
      formData._method = 'PATCH'
      updateVariation({
        query: this.dataInfo.id,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    saveData() {
      if (this.dataInfo.id) {
        this.updateItemsForShop()
      } else {
        this.addItemsForShop()
      }
    },
    getFormData() {
      const dataInfo = window.SuperApp.actions.cloneDeep(this.dataInfo)
      Object.keys(dataInfo).forEach(x => {
        if (['status', 'optional', 'multiple'].includes(x)) {
          dataInfo[x] = Number(dataInfo[x])
        }
      })
      return dataInfo
    },
    addItemsForShop() {
      const { createVaraition } = useAdminUi()
      showLoader()
      const formData = this.getFormData()
      createVaraition(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    editCategory(props) {
      showLoader()
      const { getVariationById } = useAdminUi()

      getVariationById(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopItem = data.responseData
            Object.keys(this.dataInfo).forEach(x => {
              this.dataInfo[x] = this.shopItem[x]
            })
            const boolData = ['optional', 'multiple']
            boolData.forEach(x => {
              this.dataInfo[x] = Boolean(this.dataInfo[x])
            })
            this.showVariationModal = true
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    deleteVariation(props) {
      showLoader()
      const { deleteVariation } = useAdminUi()

      deleteVariation(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.categoryRef.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            this.showVariationModal = true
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          this.showVariationModal = true
          showErrorNotification(this, error)
        })
    },
    changeCategoryStatus(props) {
      const payload = window.SuperApp.actions.cloneDeep(props)
      payload.status = payload.status === 0 ? 1 : 0
      this.dataInfo = payload
      this.updateItemsForShop()
    },
    getApiEndpoint() {
      return '/admin/variations/list'
    },
    adminStoreList() {
      const { adminStoreList } = useAdminUi()

      adminStoreList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.shopList = data.responseData
            this.shopSelectionList = data.responseData.map(x => ({
              text: x.name,
              value: x.id,
            }))
            this.shopSelectionList.unshift({
              text: 'Select Shop',
              value: '',
            })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
