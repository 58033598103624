var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"admin-variations"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('good-data-table',{ref:"categoryRef",attrs:{"button-label":_vm.$t('Add Variations'),"columns":_vm.categoryColumns,"total-column":'response.responseData.total',"data-column":'response.responseData.data',"api-endpoint":_vm.getApiEndpoint()},on:{"add-new-info":_vm.showAddModal}})],1)],1),_c('b-modal',{attrs:{"no-close-on-backdrop":true,"title":_vm.dataInfo && _vm.dataInfo.id ? 'Edit Varaition' : 'Add Varaition',"size":"lg","ok-only":"","ok-title":_vm.$t('Save Changes')},on:{"hidden":function($event){_vm.showVariationModal = false},"ok":_vm.saveData},model:{value:(_vm.showVariationModal),callback:function ($$v) {_vm.showVariationModal=$$v},expression:"showVariationModal"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Title')}},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.dataInfo.title),callback:function ($$v) {_vm.$set(_vm.dataInfo, "title", $$v)},expression:"dataInfo.title"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Status')}},[_c('b-form-select',{attrs:{"options":[
              {
                text: 'Active',
                value: '1',
              },
              {
                text: 'Inactive',
                value: '0',
              } ]},model:{value:(_vm.dataInfo.status),callback:function ($$v) {_vm.$set(_vm.dataInfo, "status", $$v)},expression:"dataInfo.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Variations')}},[_c('b-form-tags',{model:{value:(_vm.dataInfo.variation_values),callback:function ($$v) {_vm.$set(_vm.dataInfo, "variation_values", $$v)},expression:"dataInfo.variation_values"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Shop Name')}},[_c('b-form-select',{attrs:{"options":_vm.shopSelectionList},model:{value:(_vm.dataInfo.store_id),callback:function ($$v) {_vm.$set(_vm.dataInfo, "store_id", $$v)},expression:"dataInfo.store_id"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"3"}},[_c('b-form-checkbox',{attrs:{"switch":"","inline":""},model:{value:(_vm.dataInfo.optional),callback:function ($$v) {_vm.$set(_vm.dataInfo, "optional", $$v)},expression:"dataInfo.optional"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Optional')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }